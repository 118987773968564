import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ProgressIndicator, Pivot, PivotItem, Separator, IComboBoxOption } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks"
import { getPage } from "../../../../app/Pages"
import { setPage } from "../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../common/Table/Table"
import { ApiMessage, getHeaders } from "../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import { Message } from "../../../common/Message/Message"
import { ShareBox } from "../../../common/ShareBox/ShareBox"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { get, dismissMessage, resetItem, reset, updateActivity, updateItem, deleteItem, updateConnection } from "./businessClientDetailsPageSlice"
import { Popup } from "../../../common/Popup/Popup"
import { FormType } from "../../../forms/FormProps"
import { DynamicField } from "../../../common/DynamicFields/DynamicFields"
import { TextView } from "../../../common/DataView/TextView"
import { ProfileView } from "../../../common/DataView/ProfileView"
import { useReactToPrint } from "react-to-print"
import { printComponent, normalizeKey } from "../../../../app/Helpers"
import { GetBusinessClientRequest, UpdateBusinessClientStateRequest, UpdateThirdPartyConnectionResponse, UpdateBusinessClientActivityResponse, DeleteBusinessClientRequest } from "../../../../repository/UserManagement/Dimensions/businessclient_pb"
import { BusinessesForm } from "../../../forms/Businesses/BusinessesForm"
import { BusinessThirdPartyForm } from "../../../forms/Businesses/BusinessThirdPartyForm"
import { countriesAR, countriesEn } from "../../../../app/Content"
import { LinkView } from "../../../common/DataView/LinkView"
import { BusinessClientsForm } from "../../../forms/BusinessClients/BusinessClientsForm"
import { addItem } from "../BusinessClientsPageSlice"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};


let req: GetBusinessClientRequest;

let org: number = -1;

let getPromise: any;
let actionPromise: any;


const key = "businessClients/details"
export const BusinessClientDetailsPage: React.FunctionComponent = () => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let { id } = useParams();
    const [countries, setCountries] = useState([] as IComboBoxOption[])

    const state: {
        isChangeStateLoading: boolean,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        isLoading: boolean,
        item: any
    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.businessClientDetailsPage.isChangeStateLoading,
            message: state.businessClientDetailsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            isLoading: state.businessClientDetailsPage.isLoading,
            item: state.businessClientDetailsPage.item,

        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');
    const deleteActionConfirmationcalloutTargetId = useId('delete-action-callout-target');

    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
        const reactToPrintContent = () => {
        return printComponent(ref.current?.cloneNode(true), true);
    };

    const handlePrint = useReactToPrint({});
    const getActionId = (action: number): string => {
        if (action == 1) {
            return activityActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return deleteActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }


    useEffect(() => {

        req = new GetBusinessClientRequest();
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {

            }
        }
        
        const page = getPage(key)
        dispatch(setTitle(t(normalizeKey(page?.title))));
        dispatch(setPage(page));

        if (id) {
            req.setId(id)
            getPromise = dispatch(get({ body: req, headers: getHeaders() }))
        } else {
            navigate(-1)
        }


        return () => { //clean up
            getPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])



    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: t('back'),
            title: t('back'),
            iconProps: { iconName: 'back' },
            disabled: state.isLoading,
            onClick: () => {
                navigate(-1)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.isLoading,
            onClick: () => {
                dispatch(resetItem())
                getPromise = dispatch(get({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'editThirdPartyConnection',
            text: t("editThirdPartyConnection"),
            title: t("editThirdPartyConnection"),
            iconProps: { iconName: 'PlugDisconnected', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(102)
                }
            }
        },

        {
            key: 'status',
            text: (state.item && state.item?.isActive) ? t("deactivate") : t("activate"),
            title: (state.item && state.item?.isActive) ? t("deactivate") : t("activate"),
            iconProps: { id: activityActionConfirmationcalloutTargetId, iconName: (state.item && state.item?.isActive) ? "Cancel" : "Accept", styles: { root: { fontWeight: (state.item && state.item?.isActive) ? undefined : "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'delete',
            text: t("delete"),
            title: t("delete"),

            iconProps: { id: deleteActionConfirmationcalloutTargetId, iconName: "Delete", styles: { root: { fontWeight: "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 2 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 2 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 2 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(2)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },

        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.item?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t('share'),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                   handlePrint(reactToPrintContent);
                }
            }
        },
    ];



    return (
        <Stack >
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }

            <Popup isOpen={(currentAction == 100)} title={t("addBusinessClient")} onDismiss={() => { setCurrenctAction(0) }} >

                <BusinessClientsForm childrenGap={10} maxWidth={380} type={FormType.ADD} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(addItem(e))

                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            <Popup isOpen={(currentAction == 102)} title={t("editThirdPartyConnection")} onDismiss={() => { setCurrenctAction(0) }} >

                <BusinessClientsForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={state.item} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateConnection(e))
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareBusinessClient")} value={`https://${window.location.host}/business-clients/${state.item?.id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}



            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 2 ? t("delete") : currentAction == 1 ? (state.item?.isActive) ? t("deactivate") : t("activate") : "")}

                        </Text>
                        <Text block variant="small">
                            {(currentAction == 2 ? t("deleteBusinessClientDesc") : currentAction == 1 ? (state.item?.isActive) ? t("deactivateBusinessClientDesc") : t("activateBusinessClientDesc") : "")}

                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.item) {
                                        if (currentAction == 1) {
                                            let up = new UpdateBusinessClientStateRequest();
                                            up.setId(state.item?.id)
                                            up.setIsactive(!state.item?.isActive)
                                            dispatch(updateActivity({ body: up, headers: getHeaders() }))

                                        } else if (currentAction == 2) {
                                            let req = new DeleteBusinessClientRequest();
                                            req.setId(state.item?.id)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(deleteItem({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                navigate(-1)
                                            })


                                        }
                                    }
                                }} text={(currentAction == 2 ? t("delete") : currentAction == 1 ? (state.item?.isActive) ? t("deactivate") : t("activate") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}
            {state.isLoading ? <ProgressIndicator /> :

                !state.item ? null :
                    <div ref={ref}>
                        <CommandBar
                            items={_selectionItems}
                        />
                        <Stack tokens={{ childrenGap: 10, padding: "0px 36% 10px 3%" }}>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>

                                <TextView label={t("id")} value={state.item?.id} />


                                <TextView label={t("createdDate")} value={state.item?.createdDate} />

                                {state.item?.createdBy ? <ProfileView label={t("addedBy")} value={state.item?.createdBy == "" ? state.item?.createdById : state.item?.createdBy}
                                    url={"/management/users/" + state.item?.createdById + ((org == -1) ? "" : "?org=" + org)}
                                    text={state.item?.createdBy == "" ? state.item?.createdById : state.item?.createdBy}

                                    size={PersonaSize.size40} />

                                    : undefined}

                            </Stack>

                            <Stack horizontal tokens={{ childrenGap: 10 }}>

                                <ProfileView label={t("business")} value={state.item?.businessName}
                                    url={"/businesses/" + state.item?.businessId + ((org == -1) ? "" : "?org=" + org)}
                                    text={state.item?.businessName}
                                    size={PersonaSize.size40} />



                                <ProfileView label={t("client")} value={state.item?.clientName}
                                    url={"/customers/users/" + state.item?.clientId + ((org == -1) ? "" : "?org=" + org)}
                                    text={state.item?.clientName}
                                    size={PersonaSize.size40} />

                                <TextView label={t("status")} iconName={state.item?.isActive != undefined ? state.item?.isActive ? "Accept" : "Cancel" : undefined} value={state.item?.isActive != undefined ? state.item?.isActive ? t("active") : t("inactive") : ""} />

                            </Stack>

                            <LinkView label={t("thirdPartyConnection")} value={state.item?.connectionMetaData == "" ? state.item?.connectionId : state.item?.connectionMetaData + " (" + state.item?.connectionId + ")"}
                                url={"https://cs.sarafa.ly/clients/users/" + state.item?.connectionId + "?org="+ state.item?.connectionOrganization   } onLinkClick={(i) => {
                                    if (i) {
                                        i.preventDefault();
                                        window.location.href = ("https://cs.sarafa.ly/clients/users/" + state.item?.connectionId + "?org="+ state.item?.connectionOrganization)
                                    }


                                }} />





                        </Stack>

                    </div >


            }


        </Stack >



    );
}




