import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ProgressIndicator, Pivot, PivotItem } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../../../app/Hooks"
import { getPage } from "../../../../../../../app/Pages"
import { setPage } from "../../../../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../../../../common/Table/Table"
import { ApiMessage, clearSession, getHeaders, getSession } from "../../../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../../../../../app/Themes"
import { SearchField } from "../../../../../../common/SearchField/SearchField"
import { formatDate, printComponent, normalizeKey } from "../../../../../../../app/Helpers"
import { TableState } from "../../../../../../common/Table/TableSate"
import { Message } from "../../../../../../common/Message/Message"
import { ShareBox } from "../../../../../../common/ShareBox/ShareBox"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool } from "yup"
import { resetPrivilegeData, dismissMessage, getPrivilegeDataInfo, reset, updatePrivilegeData } from "./PrivilegeDataDetailsPageSlice"
import { DeleteRoleRequest } from "../../../../../../../repository/UserManagement/Customers/role_pb"
import { Popup } from "../../../../../../common/Popup/Popup"
import { GetPrivilegeDataByIdRequest } from "../../../../../../../repository/UserManagement/privilegedata_pb"
import { DataRestrictionsPage } from "./DataRestrictionsPage/DataRestrictionsPage"
import { TextView } from "../../../../../../common/DataView/TextView"
import { ProfileView } from "../../../../../../common/DataView/ProfileView"
import { useReactToPrint } from "react-to-print"


let req: GetPrivilegeDataByIdRequest;


let org: number = -1;

let getPrivilegesPromise: any;
let actionPromise: any;

interface PrivilegesPageProps {
    showAdded?: boolean,
}


const key = "customers/privilege-data/details"
export const PrivilegeDataDetailsPage: React.FunctionComponent<PrivilegesPageProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let { id } = useParams();
    const { hash } = useLocation();
    const [selectedKey, setSelectedKey] = useState("0");

    const state: {
        isChangeStateLoading: boolean,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        isLoading: boolean,
        item: any
    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.customersPrivilegeDataDetailsPage.isChangeStateLoading,
            message: state.customersPrivilegeDataDetailsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            isLoading: state.customersPrivilegeDataDetailsPage.isLoading,
            item: state.customersPrivilegeDataDetailsPage.item,
        }
    })
    const deleteActionConfirmationcalloutTargetId = useId('delete-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
        const reactToPrintContent = () => {
        return printComponent(ref.current?.cloneNode(true), true);
    };

    const handlePrint = useReactToPrint({});
    const getActionId = (action: number): string => {
        if (action == 1)
            return deleteActionConfirmationcalloutTargetId;
        else {
            return ""
        }
    }


    useEffect(() => {
        req = new GetPrivilegeDataByIdRequest()

        let current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
                if (!current.organization)
                    navigate("/login");


            } else {

                //req.setOrganization(org)
            }
        } else {
            if (!current.organization)
                navigate("/login");
        }
        const page = getPage(key)
        dispatch(setTitle(t(normalizeKey(page?.title))));
        dispatch(setPage(page));

        const privi = Number(id);
        if (id && !Number.isNaN(privi)) {
            req.setId(privi)
            getPrivilegesPromise = dispatch(getPrivilegeDataInfo({ body: req, headers: getHeaders() }))
            getPrivilegesPromise.unwrap().then((e: any) => {
                if (hash == "#restrictions") {
                    setSelectedKey("1");
                } else {
                    setSelectedKey("0");
                }
            });
        } else {

            navigate(-1)
        }

        return () => { //clean up

            getPrivilegesPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])


    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: t('back'),
            title: t("back"),
            iconProps: { iconName: 'back' },
            disabled: state.isLoading,
            onClick: () => {
                navigate(-1)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t("refresh"),
            iconProps: { iconName: 'refresh' },
            disabled: state.isLoading,
            onClick: () => {
                dispatch(resetPrivilegeData())
                getPrivilegesPromise = dispatch(getPrivilegeDataInfo({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.item?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                   handlePrint(reactToPrintContent);
                }
            }
        },
    ];




    return (
        <Stack >
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }

            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareServiceData")} value={`https://${window.location.host}/customers/privilege-data/${state.item.id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}

            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 1 ? t("removeService") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 1 ? t("deleteServiceDesc") : "")}

                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.item) {
                                        if (currentAction == 1) {

                                        }
                                    }
                                }} text={(currentAction == 1 ? t("remove") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}

            {state.isLoading ? <ProgressIndicator /> :

                !state.item ? null :
                    <Pivot selectedKey={String(selectedKey)} onLinkClick={(i) => {
                        if (i?.props.itemKey == "1")
                            //navigate("#restrictions")
                            window.history.replaceState(null, "", "#restrictions")
                        if (i?.props.itemKey == "0")
                            window.history.replaceState(null, "", "#info")

                        //navigate("#info")

                        setSelectedKey(i?.props?.itemKey ?? "0")
                    }}>
                        <PivotItem headerText={t("info")} itemKey="0"  >
                            <div ref={ref}>
                                <CommandBar
                                    items={_selectionItems}
                                />
                                <Stack tokens={{ childrenGap: 10, padding: "0px 36% 10px 3%" }}>

                                    <Stack horizontal tokens={{ childrenGap: 10 }}>

                                        <TextView label={t("id")} value={state.item?.id} />


                                        <TextView label={t("createdDate")} value={state.item?.createdDate} />

                                        {state.item?.addedBy ? <ProfileView label={t("addedBy")} value={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                            url={"/management/users/" + state.item?.addedById + ((org == -1) ? "" : "?org=" + org)}
                                            text={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                            size={PersonaSize.size40} />

                                            : undefined}

                                    </Stack>

                                    <TextView label={t("serviceData")} value={state.item?.name} />

                                    <TextView label={t("dataType")} value={state.item?.dataType ? t("inputData") : t("outputData")} />

                                    <TextView label={t("description")} value={state.item?.description} />


                                    {state.item?.dataType ? <Stack>
                                        <TextView label={t("validation")} value={state.item?.isRequired ? t("requiredData") : t("unRequiredData")} />

                                        {state.item?.validation?.enum ?
                                            <TextView label={t("enumValues")} value={state.item?.validation?.enum?.map((a: any) => {
                                                return "{" + a + "} ";
                                            })} />
                                            : undefined}
                                        {state.item?.validation?.range ?

                                            <TextView label={t("rangeValues")} value={state.item?.validation?.range?.map((a: any) => {
                                                return " ( " + t("from") + ": " + a?.from + "  " + t("to") + ": " + a?.to + " )";
                                            })} />
                                            : undefined}
                                        {state.item?.validation?.regex ? <TextView label={t("regexValues")} value={state.item?.validation?.regex} />
                                            : undefined}
                                        {state.item?.validation?.luhn ? <TextView label={t("luhnValues")} value={t("yes")} /> : undefined}
                                    </Stack> : null}
                                </Stack>
                            </div>
                        </PivotItem>
                        <PivotItem headerText={t("restrictions")} itemKey="1" >
                            <DataRestrictionsPage privilegeData={{ id: Number(id), name: state.item?.name, dataType: state.item?.dataType }} />
                        </PivotItem>
                    </Pivot>
            }

        </Stack>



    );
}
